import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ProductsCache } from './products-main/products/products-cache/products-cache.service';
import { ProductsService } from './products-main/products/products.service';
import { FactorsService } from '@questions/factors/factors.service';
import { ProService } from '@questions/pro-list/pro.service';
import { QuestionsCache } from '@questions/question-cache.service';
import { QuestionGroupsService } from '@questions/question-group/question-groups.service';
import { QuestionsService } from '@questions/question-list/questions.service';
import { QuestionTypesService } from '@questions/question-type/question-types.service';
import { VersionsService } from '@services/versions/versions.service';
import { UsersService } from './admin/users/users.service';
import { QuestionStateRuleService } from '@questions/question-state-rule/question-state-rule.service';
import { QuestionFactorsService } from '@questions/questions-shared/question-selector/question-factors.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [ProService, QuestionsService, QuestionTypesService, QuestionGroupsService, QuestionsCache, ProductsCache, ProductsService, QuestionFactorsService, FactorsService],
  exports: [],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: DataModule,
      providers: [
        ProService,
        QuestionsService,
        QuestionTypesService,
        QuestionGroupsService,
        QuestionStateRuleService,
        QuestionsCache,
        ProductsCache,
        ProductsService,
        QuestionFactorsService,
        FactorsService,
        VersionsService,
        UsersService,
      ],
    };
  }
}
